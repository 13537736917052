import { IHttpClient } from "../..";
import { GetApiAccessTokenModel, GetJsonWebTokenModel } from "../../../models/api/api_access_tokens";
import APIBase from "../APIBase";

export default class APIAccessTokensAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  public GetApiAccessTokens(): Promise<GetApiAccessTokenModel[]> {
    return this.GET({
      Path: `/api/api_access_tokens`,
    });
  }

  GetApiAccessToken(accessTokenId: string): Promise<GetApiAccessTokenModel> {
    return this.GET({
      Path: `/api/api_access_tokens/${accessTokenId}`,
    });
  }

  GetApiJsonWebToken(accessTokenId: string): Promise<GetJsonWebTokenModel> {
    return this.GET({
      Path: `/api/api_access_tokens/${accessTokenId}/jwt`,
    });
  }

  CreateApiAccessToken(
    model: Record<string, unknown>
  ): Promise<GetApiAccessTokenModel> {
    return this.POST({
      Path: `/api/api_access_tokens/`,
      Body: model,
    });
  }

  DeleteAPIAccessToken(accessTokenId: string): Promise<GetApiAccessTokenModel> {
    return this.DELETE({
      Path: `/api/api_access_tokens/${accessTokenId}`,
    });
  }
}
