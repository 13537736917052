











































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import BlogCard from '../components/Blogs/BlogCard.vue'
import GridNav from '../components/Grid/GridNav.vue'
import GridNavItem from '../components/Grid/GridNavItem.vue'
import SearchBar from '../components/Valuation/SearchBar.vue'
import CallToAction from '../components/CallToAction.vue'
import About from './About.vue'
import { API, ServiceManager, Services, ServiceType } from 'truemarket-modules/src/services'
import { GetProductModel } from 'truemarket-modules/src/models/api/products'
import { FormField, FormValue } from '@/app_code/Forms'
import { IValuationFormStepModel, RequestValuationContactModel } from 'truemarket-modules/src/models/api'
import Form from '@/components/UI/Elements/Forms/Form.vue'

@Component({
  components: {
    SearchBar,
    GridNav,
    GridNavItem,
    BlogCard,
    About,
    CallToAction
  }
})
export default class Competition extends Vue {
  public firstName = ''
  public lastName = ''
  public email = ''
  public phone = ''
  public message = ''

  public propertyOne = ''
  public propertyTwo = ''
  public propertyThree = ''
  public propertyFour = ''

  send (): void {
    const api = ServiceManager.Require<API>(ServiceType.API)

    const total = parseInt(this.propertyOne) + parseInt(this.propertyTwo) + parseInt(this.propertyThree) + parseInt(this.propertyFour)

    api.CRM.SendCompetitionEmailForm({
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      Phone: this.phone,
      RequestBearerToken: true,
      BusinessName: `Property 1: ${this.propertyOne} | Property 2: ${this.propertyTwo} 
      | Property 3: ${this.propertyThree} | Property 4: ${this.propertyFour} ---- Message: ${this.message}`,
      Subject: `$${total} Competition`
    }).then(() => {
      this.reset()
    }).catch(error => {
      console.log('API call failed', error)
      this.reset()
    })
  }

  reset (): void {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.phone = ''
    this.message = ''
    this.propertyOne = ''
    this.propertyTwo = ''
    this.propertyThree = ''
    this.propertyFour = ''
  }
}
