import { ServiceType } from '../..';
import StripeConfig from '../../../environment/modules/StripeConfig';
import { Service } from '../../IService'

export default class StripeService implements Service {
  Type: ServiceType = ServiceType.Stripe
  Id = 'Stripe'

  private Config: StripeConfig

  constructor (stripeConfig: StripeConfig) {
    this.Config = stripeConfig
  }

  GetPublishableKey (): string {
    return this.Config.PublishableKey
  }

  GetReturnUrl (): string {
    return this.Config.ReturnUrl
  }
}
