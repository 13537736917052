


























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import BlogCard from '../components/Blogs/BlogCard.vue'
import GridNav from '../components/Grid/GridNav.vue'
import GridNavItem from '../components/Grid/GridNavItem.vue'
import SearchBar from '../components/Valuation/SearchBar.vue'
import CallToAction from '../components/CallToAction.vue'
import About from './About.vue'
import { Services } from 'truemarket-modules/src/services'

@Component({
  components: {
    SearchBar,
    GridNav,
    GridNavItem,
    BlogCard,
    About,
    CallToAction
  }
})
export default class Home extends Vue {
    public basePrice = 0

    mounted (): void {
      this.getBasePriceProduct()
    }

    getBasePriceProduct (): void {
      Services.API.Product.GetBasePriceProduct().then((price) => {
        this.basePrice = price
      })
    }
}
