enum ValuationStatus
{
    None,
    Pending = 10,
    OnHold = 11,
    InProgress = 20,
    Incomplete = 22,
    Deleted = 40,
    Completed = 50,
}

export { ValuationStatus }
