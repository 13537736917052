












































import { API, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class ContactUs extends Vue {
  @Prop()
  public readonly isContactUsShown!: boolean;

  @VModel({ type: String })
  public firstName: string;

  @VModel({ type: String })
  public lastName: string;

  @VModel({ type: String })
  public email: string;

  @VModel({ type: String })
  public phone: string;

  @VModel({ type: String })
  public message: string;

  @Watch('isContactUsShown')
  checkisContactUsShown (): void {
    this.reset()
  }

  send (): void {
    const api = ServiceManager.Require<API>(ServiceType.API)

    api.CRM.SendContactEmailForm({
      FirstName: (this.$refs.firstName as HTMLInputElement).value,
      LastName: (this.$refs.lastName as HTMLInputElement).value,
      Email: (this.$refs.email as HTMLInputElement).value,
      Phone: (this.$refs.phone as HTMLInputElement).value,
      RequestBearerToken: true,
      BusinessName: (this.$refs.message as HTMLInputElement).value,
      Subject: 'TrueMarket Contact Us'
    })

    this.reset()
    this.$emit('setContactUs', false)
  }

  reset (): void {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.phone = ''
    this.message = ''
  }
}
