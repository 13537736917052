


















































































import { Component, Vue } from 'vue-property-decorator'
import BlogCard from '../components/Blogs/BlogCard.vue'
import GridNav from '../components/Grid/GridNav.vue'
import GridNavItem from '../components/Grid/GridNavItem.vue'
import SearchBar from '../components/Valuation/SearchBar.vue'
import CallToAction from '../components/CallToAction.vue'
import About from './About.vue'
import { Services } from 'truemarket-modules/src/services'
import { GetProductModel } from 'truemarket-modules/src/models/api/products'

@Component({
  components: {
    SearchBar,
    GridNav,
    GridNavItem,
    BlogCard,
    About,
    CallToAction
  }
})
export default class Pricing extends Vue {
  public products: GetProductModel[] = []

  mounted (): void {
    Services.API.Product.GetProducts().then((products) => {
      this.products = products
    })
  }
}
