import { IHttpClient } from "../..";
import { InvoiceModel } from "../../../models/api/invoices";
import { GetProductModel } from "../../../models/api/products";
import APIBase from "../APIBase";

export default class ProductAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  public GetProducts (): Promise<GetProductModel[]> {
    return this.GET({
      Path: `/api/products`
    })
  }

  GetProduct (productId: string): Promise<GetProductModel> {
    return this.GET({
      Path: `/api/products/${productId}`
    })
  }

  GetProductByTypeAndPurpose (productTypeId: string, productPurposeId: string): Promise<GetProductModel> {
    return this.GET({
      Path: `/api/products/${productTypeId}/${productPurposeId}`
    })
  }

  GetBasePriceProduct (): Promise<number> {
    return this.GET({
      Path: `/api/products/price/base`
    })
  }

  CreateInvoice (productId: string, tmReferral: string | undefined, coupon: string | undefined): Promise<InvoiceModel> {
    let query = tmReferral ? `?tmReferral=${tmReferral}` : '';

    if(query === '') {
      query += coupon ? `?coupon=${coupon}` : '';
    } else {
      query += coupon ? `&coupon=${coupon}` : '';
    }

    return this.POST({
      Path: `/api/products/${productId}/invoice${query}`
    })
  }
}
