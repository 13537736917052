import { LogLevel } from 'truemarket-modules/src/debug/Logger'
import { IConfig } from 'truemarket-modules/src/environment'
import HttpProviderType from 'truemarket-modules/src/services/httpclient/HttpProviderType'

export default {
  Http: {
    Provider: HttpProviderType.Fetch
  },
  API: {
    // Base: 'http://192.168.50.69:44384'
    // Base: 'https://localhost:44384'
    Base: 'https://api.truemarket.com.au'
  },
  Logging: {
    LogLevel: LogLevel.Debug
  },
  OAuth: {
    ConsumerId: '1fe32059-f6f3-4200-9942-f4fb325133b2'
  },
  Google: {
    MapsAPIKey: 'AIzaSyA2dAxiqy0WguFJIt4r6-VyVqvUTmAuXLA'
  },
  Stripe: {
    PublishableKey: 'pk_live_0VYoqzKdm9GKgSxDKEC8w61F00wreNzw6A',
    // PublishableKey: 'pk_test_51Jkd4SJvAQ8bcvP83Ov3LwhGzLufUOrWAHB8XfTHb7BZSyXfcwSE9qetcQzpNXRVA7GqkWqUOdm1EjiLOJjyUS2a00vMCXDMGP'
    ReturnUrl: 'https://truemarket.com.au/completed'
  },
  PracticeId: 'BAA1563E-F857-466C-84FB-4BFEA16EEBC5'
} as IConfig
