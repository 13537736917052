import GetValuerProfileModel from "../../../models/api/valuations/GetValuerProfileModel";
import UpdateValuerProfileModel from "../../../models/api/valuations/UpdateValuerProfileModel";
import CreatePracticeModel from "../../../models/api/practices/CreatePracticeModel";
import CreatePracticeResultModel from "../../../models/api/practices/CreatePracticeResultModel";
import APIBase from "../APIBase";

export default class PracticesAPI extends APIBase {
  async CreatePractice(model: CreatePracticeModel): Promise<CreatePracticeResultModel> {
    const result = await this.POST<CreatePracticeResultModel>({
      Path: "/api/practices",
      Body: model,
      Anon: true,
    });

    return result;
  }

  GetValuersInPractice(practiceId: string): Promise<GetValuerProfileModel[]> {
    return this.GET({
      Path: `/api/practices/${practiceId}/valuers`,
    });
  }

  GetMeInPractice(practiceId: string): Promise<GetValuerProfileModel> {
    return this.GET({
      Path: `/api/practices/${practiceId}/valuers/me`,
    });
  }

  SetMeInPractice(
    practiceId: string,
    model: UpdateValuerProfileModel
  ): Promise<GetValuerProfileModel> {
    return this.PUT({
      Path: `/api/practices/${practiceId}/valuers/me`,
      Body: model,
    });
  }
}
