import AppConfig from './config'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Bootstrapper } from 'truemarket-modules/src/startup'

import './components/Layout/global'
import './components/Typography/global'
import './components/UI/global'

import PortalVue from 'portal-vue'

Vue.config.productionTip = false
Vue.prototype.$valuationFromVersion = 'TM-Console-1.0'

Vue.use(PortalVue)

Bootstrapper.Initialise(AppConfig).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
