import Vue from 'vue'

import Btn from './Btn.vue'
import LinkButton from './LinkButton.vue'
import Loader from './Loader.vue'
import Alert from './Alert.vue'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'

Vue.use(ConfirmationService)
Vue.use(PrimeVue)

Vue.component('Btn', Btn)
Vue.component('LinkButton', LinkButton)
Vue.component('Loader', Loader)
Vue.component('Alert', Alert)
